.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    margin-left: 50px;
    overflow-x: hidden;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.utilitiesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    margin-top: 1.5rem;
    font-size: 0.8rem;
    padding: 10px;
}

.optionContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    gap: 20px;
    border-radius: 10px;
    text-align: center;
    flex: 1 1;
    justify-content: space-between;
    min-width: 300px;
    max-width: 300px;
    height: 200px;
}

.options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.heading {
    display: flex;
    align-self: center;
    padding: 10px;
    color: black;
    font-size: 24px;
}

.navigationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.active {
    gap: unset;
}

.optionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}

.optionContainer {
    flex: 0 0 calc(33.33% - 16px);
    min-width: 300px;
    max-width: 400px;
}

.optionButton {
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
}

.options p {
    color: black;
    font-size: 16px;
}

.utilitiesScrollContainer {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 4rem;
    position: relative;
}

@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .whiteContainer {
        padding-bottom: 9rem;
        margin-top: 0;
    }

    .optionsContainer {
        flex-direction: column;
        padding: 0 1rem;
    }

    .optionContainer {
        min-width: 100%;
        height: 180px;
    }

    .options p {
        font-size: 14px;
    }

    .options span {
        font-size: 12px;
    }

    .utilitiesContainer {
        gap: 1.5rem;
        margin-top: 1rem;
    }

    .utilitiesScrollContainer {
        padding-bottom: 8rem;
        -webkit-overflow-scrolling: touch;
    }
}