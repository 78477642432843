.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  height: 100%;
}

.newAdmissionContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 1rem;
}

.viewBtn {
  display: flex;
  width: 100%;
  background-color: var(--light-purple);
  color: white;
  max-width: 40px;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.viewBtn:hover {
  background-color: var(--purple);
  color: white;
}

/* mobile */
@media (max-width: 630px) {
  .container {
    margin: 0;
    height: calc(100% - 160px);
    justify-content: center;
  }

  .newAdmissionContainer {
    padding: 1rem;
    margin-bottom: 0rem;
  }
}

@media (max-width: 1000px) and (orientation: landscape) {
  .container {
    margin-top: 0;
  }

  .newAdmissionContainer {
    margin-bottom: 0;
    padding: 0.5rem 1rem;
  }

}