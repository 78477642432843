.dashboard-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 1.5rem 0;
    background-color: white;
    border-radius: 15px;
}

.stat-main {
    display: flex;
    padding: 2rem 0;
}

.dash-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D3FFE7;
    border-radius: 100px;
    margin-right: 0.8rem;
    height: 50px;
    height: 50px;
}

.border {
    border-left: 1px solid var(--whitesmoke);
    height: 65%;
}

.stat-details {
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;
}

.stat-info {
    font-size: 0.6rem;
    color: var(--font-grey)
}

.stat-count {
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}

@media (max-width: 630px) {
    .dashboard-container {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .dashboard-container {
        margin: 0.3rem 0;
    }

    .stat-main {
        padding: 0.2rem 0;
        align-items: center;
    }

    .dash-icon {
        height: 25px;
        width: 25px;
        margin-right: 0.5rem;
    }

    .dash-icon svg {
        height: 20px !important;
        width: 20px !important;
    }

    .stat-details {
        line-height: 1rem;
    }

    .stat-info {
        font-size: 0.5rem;
    }

    .stat-count {
        font-size: 0.9rem;
    }

    .border {
        height: 50%;
    }

}