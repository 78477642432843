.noteContainer {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
}

.macroMateMain {
  flex: 1 1 60%;
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
}

.noteForm {
  flex: 1 1 40%;
  max-width: 450px;
  min-width: 325px;
  box-sizing: border-box;
  height: 100%;
  padding: 1rem;
}

@media (max-width: 630px) {
  .noteContainer {
    margin-bottom: 60px;
  }
}

@media (max-width: 1350px) {

  .macroMateMain,
  .noteForm {
    max-width: 100%;
    min-width: auto;
    height: auto;
  }
}