.signupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    margin-top: 1rem;
}


.topContainer {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    font-size: 0.8rem;
    gap: 6px;

}

.topContainer1 {
    display: flex;
    justify-content: flex-end;
}

.signupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    width: 80%;
    border-radius: 15px;
    margin-bottom: 35px;
    align-self: center;
}

.signupForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signUpButtonWrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
}

.signUpText {
    color: var(--purple);
}

.signUpDivider {
    align-self: stretch;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    width: 100px;
    align-self: inherit;
}

.iAcceptTerms {
    font-size: 0.8rem;
}

.inputField {
    background-color: var(--whitesmoke);
    border: 0;
    border-radius: 5px;
    padding: 11px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}

.inputField::placeholder {
    color: var(--placeholder-grey);
}

.termsCheckbox {
    display: flex;
    justify-content: flex-start;
    gap: 0.4rem;
}

.error {
    font-size: 0.65rem;
    align-self: flex-start;
    color: red;
}

.submitButton {
    background-color: var(--purple);
    color: white;
    border: 0;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
}

.signUpBottomBox {
    font-size: 0.8rem;
}

.signUpBottomLink {
    color: var(--purple)
}

.loading {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.OtpContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.codeText {
    font-size: 0.7rem;
    align-self: center;
    text-align: center;
}

.resendCodeText {
    font-size: 0.6rem;
    align-self: center;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 630px) {

    .signupForm {
        width: 85%;
    }

    .signupContainer {}

}

@media (min-width: 740px) {

    .signupForm {
        width: 80%;
    }

    .signupContent {
        width: 40%;
        max-width: 450px;
    }

}

/* Add this landscape media query at the end of the file */
@media (max-width: 1000px) and (orientation: landscape) {
    .signupContainer {
        height: auto;
        min-height: 100vh;
    }

    .signupContent {
        max-height: none;
        height: auto;
        width: 90%;
        max-width: 700px;
    }

    .signupForm {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.5rem;
        padding: 10px;
        max-height: 80vh;
    }

    .signUpButtonWrapper {
        width: 100%;
        margin: 10px 0;
        text-align: center;
    }

    /* Create two columns for input fields */
    .inputWrapper {
        width: 48%;
        margin: 0.3rem 0;
    }

    /* Make certain elements full width */
    .inputWrapper:nth-last-child(1),
    .inputWrapper:nth-last-child(2) {
        width: 100%;
    }

    .OtpContainer {
        width: 100%;
    }

    .inputField {
        padding: 8px;
        font-size: 14px;
    }

    .submitButton {
        padding: 8px;
        margin-top: 10px;
    }

    .iAcceptTerms {
        font-size: 0.7rem;
    }

    .error {
        font-size: 0.6rem;
    }

    .codeText,
    .resendCodeText {
        margin: 5px 0;
    }

    /* Adjust OTP container */
    .OtpContainer {
        margin-top: 10px;
    }

    /* Handle the navigation container */
    .NavigationContainer {
        width: 100%;
        margin-bottom: 10px;
    }

    /* Ensure scrolling works properly */
    .signupContainer::-webkit-scrollbar,
    .signupContent::-webkit-scrollbar,
    .signupForm::-webkit-scrollbar {
        width: 5px;
    }

    .signupContainer::-webkit-scrollbar-thumb,
    .signupContent::-webkit-scrollbar-thumb,
    .signupForm::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
}