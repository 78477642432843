.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.diagnosisContainer {
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    display: flex;
    width: 100%;
    justify-content: center;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 40px;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    max-width: 1200px;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    background-color: var(--tabs-grey);
    justify-content: space-evenly;
    align-items: center;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.activeTab {
    border-top: 5px solid var(--purple);
    background-color: white;
    color: black;
}

.facesheetContainer {
    width: 90%;
    max-width: 1000px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1000px;
    flex: 1;
    background-color: white;
}

.viewBtn {
    display: flex;
    width: 100%;
    background-color: var(--light-purple);
    color: white;
    max-width: 100px;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.actionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    gap: 0.5rem;

}

.viewBtn:hover {
    background-color: var(--purple);
    color: white;
}

.facesheetRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--font-grey);
    border-radius: 5px;
    padding: 2px 4px;
    align-items: center;
}

.subContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 0;
    padding-bottom: 3rem;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.columnContainer {
    display: flex;
    gap: 0.4rem;
    align-items: baseline;
    width: 100%;
}

.inputField {
    background-color: var(--whitesmoke);
    border: 0;
    border-radius: 5px;
    padding: 11px 10px;
    text-align: center;
    outline: none;
    height: 45px;
    max-width: 100%;
}

.select {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectInputField {
    background-color: var(--whitesmoke);
    border-radius: 5px;
    color: black;
    padding: 10px 10px;
    height: 45px;
    text-align: center;
    text-align-last: center;
}

.dateField {
    background-color: var(--whitesmoke);
    color: black;
    border: 0;
    border-radius: 5px;
    padding: 15px 10px;
    height: 45px;
    text-align: center;
    text-align-last: center;
    outline: none;
    font-size: 0.8rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

label {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.7rem;
    margin-bottom: 0;
    margin-top: 1rem;
}

.dateField::placeholder {
    text-align: center;
    text-align-last: center;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 50%;
}

.rowContainer {
    display: flex;
    flex-direction: row;
}

.inactiveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    background-color: var(--tabs-grey);
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.activeButton {
    background-color: var(--purple);
    color: white;
}

.rectangleBtn {
    display: block;
    width: 100%;
    padding: 7px;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 5px;
    background-color: var(--light-purple);
    color: white;
    margin-bottom: 2rem;
    cursor: pointer;
    margin-top: 16px;
}

.rectangleBtn:hover {
    background-color: var(--purple);
}

.required {
    color: red;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    color: black;
    margin: 14px 0;
}

.inputField,
.dateField,
.selectInputField {
    width: 100%;
}

.content {
    width: 90%;
    max-width: 1000px;
}

.subContent {
    width: 100%;
    padding: 0 2rem;
}

.consultsDetailsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 20px;
    padding: 20px;
    margin: 40px 0;
    align-self: center;
}

.consultDetailsInput {
    border: 1px solid rgb(184, 174, 174);
    border-radius: 5px;
    padding: 11px 10px;
    height: 40px;
    color: black;
    font-size: 0.8rem;
    width: 100%;
}

.consultDetailsSelectField {
    border-radius: 5px;
    color: black;
    height: 40px;
    border: 1px solid rgb(184, 174, 174);
    outline: 0;
    padding: 0 10px;
    font-size: 0.8rem;
}

.consultDetailsDateField {
    color: black;
    border: 1px solid rgb(184, 174, 174);
    border-radius: 5px;
    padding: 11px 10px;
    height: 40px;
    font-size: 0.8rem;
}

.consultDetailsHeadings {
    color: black;
    align-self: baseline;
    margin: 10px 0;
    font-weight: 400;
}

.customOptions {
    display: flex;
    gap: 10px;
}

.customInactiveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border-radius: 6px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgb(184, 174, 174);
    color: black;
    font-size: 14px;
}

.customInactiveButton.activeButton {
    background-color: var(--purple);
    color: white;
    border: 1px solid var(--purple);
}

/* mobile */
@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
        overflow-y: auto;
        min-height: 100vh;
        background-color: white;
    }

    .columnContainer {
        width: 100%;
        gap: 0.5rem;
    }

    .btnContainer {
        width: 80%;
        margin-top: 20px;
    }

    .inputField {
        padding: 15px 3px;
        font-size: 0.8rem;
    }

    .inactiveButton {
        width: 150px;
    }

    .pb200 {
        padding-bottom: 200px
    }

    #ChargesHistory {
        font-size: 0.8rem;
    }

    .inputField,
    .dateField,
    .selectInputField {
        width: 100%;
    }

    .content {
        padding: 1rem;
        flex: 1;
        width: 100%;
        padding: 1rem;
        background-color: unset
    }

    .subContent {
        width: 100%;
        padding: 0 1rem;
    }

}

/* desktop */
@media (min-width: 631px) {}