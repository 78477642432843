.column::-webkit-scrollbar {
  width: 5px;
  /* Thin scrollbar width */
}

.column::-webkit-scrollbar-track {
  background: #f3f4f6;
  /* Track color */
  border-radius: 5px;
}

.column::-webkit-scrollbar-thumb {
  background: #bbb;
  /* Scrollbar handle color */
  border-radius: 5px;
}

.column::-webkit-scrollbar-thumb:hover {
  background: #999;
  /* Darker on hover */
}

.selectedContainer::-webkit-scrollbar {
  width: 5px;
  /* Thin scrollbar width */
}

.selectedContainer::-webkit-scrollbar-track {
  background: #f3f4f6;
  /* Track color */
  border-radius: 5px;
}

.selectedContainer::-webkit-scrollbar-thumb {
  background: #bbb;
  /* Scrollbar handle color */
  border-radius: 5px;
}

.selectedContainer::-webkit-scrollbar-thumb:hover {
  background: #999;
  /* Darker on hover */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}

.box {
  display: flex;
  background-color: white;
  padding: 5px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.DiagnosisBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 0.5rem;
}

.column {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 50%;
  padding: 2px;
  border-right: 2px solid #ddd;
}

.column:last-child {
  border-right: none;
}

/* List Container */
.listContainer {
  margin-top: 8px;
  overflow-y: auto;
  height: 160px;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #f9f9f9;
}

.selectedContainer {
  width: 100%;
  height: 198px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 8px;
}

.DiagnosisContainer {
  flex: 1 1 50%;
  height: 260px;
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 5px;
}

.accordionTitle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--light-purple);
  color: white;
  padding: 0.5rem;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
}

.accordionTitle:hover {
  background-color: var(--purple);
}

.DiagnosisItem {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.6rem;
  border-bottom: 1px solid rgb(199, 196, 196);
  border-top: none;
  gap: 0.7rem;
  width: 100%;
}

.DiagnosisItemCode {
  display: flex;
  padding: 5px;
}

.DiagnosisItemDescription {
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
}

.checkmarkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

@media (max-width: 630px) {
  .container {
    width: 86%;
  }

  .checkmarkIcon {
    width: 25px;
    height: 25px;
  }

  .accordionTitle {
    font-size: 0.6rem;
    padding: 0.4rem;
    font-weight: 500;
  }

  .DiagnosisBlock {
    padding: 2px;
  }

  .DiagnosisItem {
    font-size: 0.5rem;
  }
}