@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

:root {
  --light-purple: #7557e3;
  --table-purple: #6c7ae0;
  --purple: #5E3AE4;
  --container-grey: #EEEEEE;
  --font-grey: #9197B3;
  --bg-white: #FAFBFF;
  --whitesmoke: #f5f5f5;
  --placeholder-grey: rgb(150, 147, 147);
  --icon-bg-grey: #b0b9e1;
  --tabs-grey: #DBE6F2;
  /* fonts */
  --font-poppins: Poppins;
  /* transitions */
  --trans-02: all 0.2s ease;
  --trans-03: all 0.3s ease;
  --trans-04: all 0.4s ease;
  --trans-05: all 0.5s ease;
  --trans-06: all 0.6s ease;
}

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default font styles for the entire document */
body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  background-color: var(--bg-white);
  color: var(--font-grey);
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb5 {
  padding-bottom: 5rem;
}


.marginContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

a {
  color: var(--purple);
  text-decoration: none;
}

input {
  border: none;
  outline: none;
}

input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.inputDefault {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 56%;
  margin-bottom: 10px;
}

.NavbarLink {
  cursor: pointer;
}

#tableHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--table-purple);
  color: white;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
  padding: 0 0.5rem;
}

.white-btn {
  border-radius: 42px;
  background-color: white;
  color: var(--purple);
  padding: 8px 18px 8px 18px;
}

.purple-btn {
  border-radius: 5px;
  background-color: var(--light-purple);
  padding: 8px 18px 8px 18px;
  width: 100px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.purple-btn:hover {
  background-color: var(--purple);
}

.App {
  display: flex;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.back-button-container {
  display: flex;
  flex-direction: flex-start;
  width: 100%;
  max-width: 1140px;
}

.back-button {
  cursor: pointer;
  height: 35px;
  width: 35px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  max-width: 1000px;
  font-size: 0.7rem;
}

.toggle {
  width: 40px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
}

.toggle-slider {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-slider.active {
  transform: translateX(20px);
  background-color: var(--purple);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-item-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
}

.modal-item {
  display: flex;
  flex-direction: row;
}

.labelLeftMarginUnset {
  margin-left: 0;
}

.modalContent {
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: white;
  min-height: 80vh;
  height: 100%;
  padding: 1rem;
  border-radius: 5px;
  min-width: 500px;
}

.NavigationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1600px;
  margin-bottom: 1rem;
}

.closeModalButton {
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.dhx_section_time {
  justify-content: center;
}

/* horizontal loading animation */
.horizontal-loading {
  width: 100%;
  height: 4px;
  background-color: var(--light-purple);
  position: relative;
  overflow: hidden;
}

.horizontal-loading::before {
  content: "";
  position: absolute;
  left: -50%;
  height: 100%;
  width: 50%;
  background-color: var(--purple);
  animation: loading 0.5s linear infinite;
}

/* spinner animation class */
.spinner {
  display: flex;
  align-self: center;
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.dhx_scale_time_slot,
.dhx_cal_event {
  width: 100% !important;
}

.dhx_scale_hour,
.dhx_scale_holder_now {
  height: 100px !important;
}

.purple {
  --dhx-scheduler-event-background: linear-gradient(180deg, #5E3AE4 0%, #7557e3 100%);
}

.violet {
  --dhx-scheduler-event-background: linear-gradient(180deg, #D071EF 0%, #EE71D5 100%);
}

.green {
  --dhx-scheduler-event-background: linear-gradient(180deg, #12D979 0%, #1ECDEB 100%);
}

.yellow {
  --dhx-scheduler-event-background: linear-gradient(180deg, #FFB725 0%, #FFBB25 31.25%, #FAEA27 100%);
}

.blue {
  --dhx-scheduler-event-background: linear-gradient(180deg, #3498db 0%, #2980b9 100%);
}

.orange {
  --dhx-scheduler-event-background: linear-gradient(180deg, #f39c12 0%, #e67e22 50%, #d35400 100%);
}

.red {
  --dhx-scheduler-event-background: linear-gradient(180deg, #e74c3c 0%, #c0392b 100%);
}

.bg-red {
  background: linear-gradient(180deg, #e74c3c 0%, #c0392b 100%);
  color: white;
  border: none;
}

.bg-red:hover {
  background: linear-gradient(180deg, #ff6b6b 0%, #e74c3c 100%);
  color: white;
  border: none;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.mb-6 {
  margin-bottom: 0;
}

.select-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.left-div {}

.center-div {
  font-size: 1.6rem;
  text-align: center;
}

.right-div {}

.select-container select {
  max-width: 300px;
}

.dhx_cal_event .dhx_body {
  display: none !important;
}

.dhx_cal_month_table {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  height: 100%;
}

.dhx_cal_month_row {
  display: flex;
  height: auto;
  /* Prevents fixed height conflicts */
  min-height: 100%;
  /* Fills the available row space */
  align-self: stretch;
}


.dhx_cal_event.dhx_cal_select_menu {
  padding: 0 !important;
  box-shadow: none !important;
}

.staff-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-bottom: 7rem;
}

.hidden-section {
  display: none;
}

.modern-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modern-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: modalFadeIn 0.3s ease-out;
}

.modern-modal-header {
  font-size: 1.5rem;
  color: var(--purple);
  margin-bottom: 1.5rem;
  text-align: center;
}

.modern-modal-body {
  margin-bottom: 2rem;
}

.modern-modal-footer {
  display: flex;
  justify-content: space-between;
}

.modern-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.modern-btn-primary {
  background-color: var(--purple);
  color: white;
  border: none;
}

.modern-btn-secondary {
  background-color: var(--container-grey);
  color: var(--font-grey);
  border: none;
}

.modern-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.manage-staff-link {
  color: blue;
  cursor: pointer;
}

.dhx_cal_checkbox label {
  text-align: left;
  margin-left: 0;
}

.hoverContent {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  pointer-events: none;
  transform: translate(50%, -50%);
  min-width: 250px;
}

.hoverContent1 {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  pointer-events: none;
  transform: translate(-100%, -150%);
  min-width: 250px;
}

.past-event {
  opacity: 0.7;
}

.radio-group {
  display: flex;
  gap: 20px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  cursor: pointer;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.whiteContainerWide {
  max-width: 1600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.file-input-container {
  position: relative;
  display: inline-block;
}

.file-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-input-label:hover {
  background-color: #45a049;
}

.file-input-label:active {
  transform: scale(0.98);
}

.UtilitiesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 35px - 60px);
  padding: 2rem;
  margin-left: 50px;
  overflow-x: hidden;
}

.UtilitiesWhiteContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
}

.UtilitiesMainContainer {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  background: white;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  overflow-y: auto;
  margin-bottom: 2rem;
  border-radius: 30px;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

@keyframes loading {
  0% {
    left: -50%;
  }

  100% {
    left: 100%;
  }
}

.dhx_cal_data {
  scrollbar-width: thin;
  scrollbar-color: rgba(136, 136, 136, 0.7) transparent;
}

.dhx_cal_data::-webkit-scrollbar {
  width: 6px;
}

.dhx_cal_data::-webkit-scrollbar-track {
  background: transparent;
}

.dhx_cal_data::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.7);
  border-radius: 10px;
}

.dhx_cal_data::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.9);
}

.dhx_cal_data {
  -ms-overflow-style: auto;
}

@media (max-width: 630px) {

  .DesktopNavbarContainer {
    display: none;
  }

  .marginContainer {
    padding: 0rem;
  }

  .paddingBottomContainer {
    padding-bottom: 2rem;
  }

  .loginContainer {
    width: 32%;
  }

  .modalContent {
    height: 80vh;
    min-width: 80vw;
    width: 100%;
    padding: 1rem;
  }

  .mb-6 {
    margin-bottom: 8.5rem;
  }

  .mb-10 {
    padding-bottom: 10rem;
  }

  .dhx_cal_light {
    width: 100%;
  }

  .select-container {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 0.5rem;
  }

  .right-div {}

  .center-div {
    width: 100%;
  }

  .left-div {
    display: none;
  }

  .modern-modal-content {
    width: 100%;
  }

  .UtilitiesContainer {
    padding: 0.5rem;
    margin-left: 0;
    padding-bottom: 1.9rem
  }

  .UtilitiesMainContainer {
    padding: 0.5rem;
  }

  .dhx_month_link {
    font-size: 10px;
  }

  .marginBottom5 {
    margin-bottom: 5rem;
  }

  .marginBottom4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 631px) {

  .MobileNavbarContainer {
    display: none;
  }

  .App-header {
    font-size: 14px;
  }

  .loginForm {
    width: 100%;
  }
}

/* Landscape mode for mobile devices */
@media (max-width: 1000px) and (orientation: landscape) {
  body {
    overflow-y: auto;
    position: static;
  }

  .UtilitiesContainer {
    padding: 0.5rem 1rem;
    margin-left: 0;
    min-height: auto;
  }

  .UtilitiesMainContainer {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .NavigationContainer {
    margin-bottom: 0.5rem;
  }

  .marginContainer {
    padding: 0.5rem;
  }

  .paddingBottomContainer {
    padding-bottom: 0.5rem;
  }
}