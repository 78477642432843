.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    text-align: center;
    position: relative;
}

.container2 {
    min-height: 600px;
}

.tableContainer {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--purple);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.8rem;
    color: black;
}

th,
td {
    padding: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

th {
    cursor: pointer;
}

tbody tr {
    transition: background-color 0.2s ease;
}

tbody tr:hover {
    background-color: rgba(147, 112, 219, 0.2) !important;
    cursor: pointer;
}

tbody tr:nth-child(even) {
    background-color: var(--whitesmoke);
}

.tableWrapper {
    position: absolute;
    top: 0rem;
    left: 1rem;
    right: 1rem;
    bottom: 50px;
    overflow-y: auto;
}


.totalCounter {
    text-align: left;
    color: black;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: white;
    padding: 1rem;
    z-index: 1;
}

.truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

@media (max-width: 630px) {
    .mobileText {
        font-size: 0.6rem;
    }

    .compactTable th,
    .compactTable td {
        padding: 0.14rem;
    }

    .compactTable2 th,
    .compactTable2 td {
        padding: 0.5rem;
    }

    .container2 {
        min-height: 300px;
    }

}

@media (max-width: 1000px) and (orientation: landscape) {

    .compactTable2 th,
    .compactTable2 td {
        padding: 0.5rem;
    }
}