.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  max-width: 800px;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 20px;
  max-height: 95vh;
  overflow-y: auto;
  scroll-padding-top: 20px;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  padding-top: 15px;
  scroll-padding-top: 20px;
}

.formContainer h2 {
  color: black;
  margin: 0;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  max-width: 500px;
}

.selectInputField {
  background-color: var(--whitesmoke);
  border-radius: 5px;
  color: black;
  padding: 10px 10px;
  height: 45px;
  text-align: center;
  text-align-last: center;
  border: 0;
  outline: 0;
  width: 100%;
  min-width: 250px;
}

.required {
  color: red;
}

.rectangleBtn {
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
  border: none;
  background-color: var(--light-purple);
  color: white;
  cursor: pointer;
  margin-top: 24px;
}

.rectangleBtn:hover {
  background-color: var(--purple);
}

.resultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .formContainer {
    width: 95%;
    padding: 15px;
    padding-bottom: 35px;
  }

  .inputWrapper {
    width: 80%;
  }

  .rectangleBtn {
    font-size: 0.9rem;
    padding: 8px;
    width: 80%;
  }
}