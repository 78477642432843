.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.loginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    width: 80%;
    height: 100%;
    max-height: 500px;
    border-radius: 15px;
    margin-bottom: 35px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.inputField {
    background-color: #f7f9fa;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 16px;
}

.inputField::placeholder {
    color: var(--placeholder-grey);
}

.signInButtonWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.welcomeText {
    color: black;
    font-size: 28px;
    font-weight: 600;
    line-height: 2.2;
}

.signInText {
    color: var(--font-grey);
}

.signInDivider {
    align-self: stretch;
    color: var(--purple);
    border-bottom: 1px solid var(--purple);
    width: 100px;
    align-self: inherit;
}

.loginForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}

.submitButton {
    background-color: var(--purple);
    color: white;
    border: 0;
    padding: 16px 10px;
    border-radius: 8px;
}

.signInBottomBox {
    margin-bottom: 10px;
    text-align: center;
}

.signInBottomLink {
    color: var(--purple)
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.error {
    font-size: 12px;
    align-self: center;
    color: red;
}

.loading {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.rememberForgotWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;

    label {
        margin: 0;
    }
}

.rememberMeLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 12px;
}

.forgotPasswordButton {
    background: none;
    border: none;
    color: var(--purple);
    text-decoration: none;
    cursor: pointer;
    padding: 0;
}

.forgotPasswordButton:hover {
    opacity: 0.8;
}


@media (max-width: 630px) {

    .loginForm {
        width: 85%;
    }

    .signInBottomLink {
        font-size: 14px;
    }

}

@media (min-width: 631px) {

    .loginForm {
        width: 80%;
    }

    .loginContent {
        width: 40%;
        max-width: 450px;
    }

}

@media (max-width: 1000px) and (orientation: landscape) {
    .loginContainer {
        height: auto;
        min-height: 100vh;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginContent {
        max-height: none;
        height: auto;
        width: 90%;
        max-width: 500px;
        margin: 10px 0;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginForm {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
    }

    .signInButtonWrapper {
        width: 100%;
        margin-top: 0;
        text-align: center;
    }

    .inputWrapper {
        width: 80%;
        gap: 0.5rem;
        margin-bottom: 10px;
    }

    .submitButton {
        width: 80%;
        padding: 10px;
    }

    .signInBottomBox {
        width: 80%;
        margin-top: 10px;
        margin-bottom: 0;
        text-align: center;
    }

    .welcomeText {
        font-size: 22px;
        line-height: 1.5;
    }

    .signInText {
        font-size: 14px;
    }

    .inputField {
        padding: 10px;
    }

    .rememberForgotWrapper {
        margin: 5px 0;
        width: 100%;
    }
}