.hoverContainer {
    position: relative;
    display: inline-block;
}

.hoverContent {
    position: fixed;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    white-space: normal;
    width: auto;
}

.hoverContent::-webkit-scrollbar {
    width: 8px;
}

.hoverContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.hoverContent::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.hoverContent::-webkit-scrollbar-thumb:hover {
    background: #555;
}