.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 2rem;
    margin-left: 50px;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    color: black;
    width: 80vw;
    height: calc(100vh - 120px);
    overflow: hidden;
}

.profileContainer {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 80px;
    /* Increase this value */
    padding-bottom: 100px;
}

.mainheading {
    padding: 20px 0px;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    background-color: var(--tabs-grey);
    justify-content: space-evenly;
    align-items: stretch;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: var(--font-grey);
    text-align: center;
}

.activeTab {
    border-top: 5px solid var(--purple);
    background-color: white;
    color: black;
}

.commonOuterProfileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.imageNameContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 6px;
}

.imageContainer {
    padding: 10px
}

.profileImage {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.profileImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nameContainer {
    display: flex;
    flex-direction: column;
}

.editButtonContainer {
    padding: 20px;
    align-self: baseline;
}

.editButton {
    display: flex;
    gap: 2px;
    padding: 4px 8px;
    align-items: center;
    background-color: aliceblue;
    border: 1px solid grey;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    border-radius: 8px;
}

.personalInformationContainer {
    display: flex;
    width: 100%;
    gap: 6px;
    padding: 20px;
    flex-direction: column;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin: 20px 0px;
}

.infoRow div {
    flex: 1;
    margin-right: 10px;
}

.infoRow div:last-child {
    margin-right: 0;
}

.infoRow label {
    font-size: 14px;
    color: var(--font-grey);
    display: block;
}

.notificationInfoRow {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.notificationInfoRow1 {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.timeInputContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* flex-wrap: wrap; */
}

.timeInputWrapper span {
    margin: 0 4px;
}

.timeInput {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.directory-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
}

.directory-searchHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.directory-searchInput {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.directory-resultsInfo {
    margin-right: 20px;
    color: #555;
}

.directory-actions {
    display: flex;
    align-items: center;
}

.directory-actions a {
    margin-right: 20px;
    color: #0078d7;
    text-decoration: none;
}

.directory-sort {
    display: flex;
    align-items: center;
}

.directory-sort label {
    margin-right: 5px;
}

.directory-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 20px;
}

.directory-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-left: 4px solid;
}

.directory-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.directory-info {
    flex: 1.5;
    display: flex;
    align-items: center;
}

.directory-position {
    flex: 1;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.position-title {
    font-weight: 600;
}

.directory-info h4 {
    margin: 0;
    font-size: 16px;
}

.directory-name {
    margin-left: 20px;
}

.directory-name .mobile-view-title {
    display: none;
}

.directory-name .mobile-view-phone {
    display: none;
}

.directory-info .directory-username {
    display: none;
}

.directory-info .desktop-view-company-name {
    color: #d9534f;
    font-size: 14px;
}

.directory-contact {
    flex: 1;
    text-align: right;
    word-wrap: break-word;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.directory-pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.directory-loader {
    text-align: center;
    padding: 40px;
    font-size: 18px;
}

.shimmer {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
}

.submitButton {
    background-color: var(--light-purple);
    color: white;
    padding: 10px 24px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    max-width: 200px;
    margin-left: 15px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.securityAndPrivacyContainer {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: black;
    border-radius: 30px;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 40px;
}

.personalInformationheading {
    display: flex;
    flex-direction: column;

}

.securityAndPrivacyContainer .commonOuterProfileContainer {}

.securityAndPrivacySignupForm {
    width: 50%;
    align-self: center;
}

.card {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    margin-bottom: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
}

.card p {
    color: var(--font-grey);
}

.cardIcon {
    padding: 8px;
}

.cardDetails {
    display: flex;
    flex-direction: column;
}

.switch {
    display: flex;
    gap: 4px;
    margin: 4px;
}

.toggleSwitch {
    display: flex;
    width: 80px;
    height: 25px;
    border-radius: 15px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, justify-content 0.3s ease;
}

.toggleSwitch.active {
    background-color: green;
    justify-content: flex-end;
}

.toggleSwitch.inactive {
    background-color: gray;
    justify-content: flex-start;
}

.toggleButton {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggleLabel {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: white;
    z-index: 1;
}


@media (max-width: 630px) {
    .mainContainer {

        padding: 10px;
        margin-left: 0;
    }

    .tab {
        font-size: 12px;
    }

    .whiteContainer {
        width: 100%;
        padding: 0;
    }

    .commonOuterProfileContainer {
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .infoRow {
        flex-direction: column;
    }

    .contactInfoRow {
        gap: 20px;
    }

    .addressInfoRow {
        flex-direction: row;
    }

    .directory-contact {
        display: none;
    }

    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-position {
        display: none;
    }

    .directory-info .directory-username {
        color: #d9534f;
        font-size: 14px;
        display: block;
    }

    .directory-info .desktop-view-company-name {
        display: none;
    }

    .timeInputContainer {
        flex-wrap: wrap;
    }

    .securityAndPrivacySignupForm {
        width: 100%;
    }

    .submitButton {
        margin-left: 0;
    }

}

@media (min-width: 630px) and (max-width: 840px) {

    .tab {
        font-size: 14px;
    }

    .whiteContainer {
        width: 100%;
        padding: 0;
    }

    .commonOuterProfileContainer {
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .infoRow {
        flex-direction: column;
    }

    .contactInfoRow {
        gap: 20px;
    }

    .addressInfoRow {
        flex-direction: row;
    }

    .directory-contact {
        display: none;
    }

    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-info .desktop-view-company-name {
        display: none;
    }

    .directory-position {
        display: none;
    }

    .directory-info .directory-username {
        color: #d9534f;
        font-size: 14px;
        display: block;
    }

}

@media (min-width: 840px) and (max-width: 1024px) {
    .directory-info {
        align-items: center;
        margin-left: 0;
    }

    .directory-name .mobile-view-title {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-name .mobile-view-phone {
        display: block;
        font-weight: 600;
        color: var(--font-grey);
    }

    .directory-position {
        display: none;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {

    .mainContainer {
        padding: 1rem;
        margin-left: 0;
    }

    .profileContainer {
        margin-bottom: 0;
    }

    .timeInputWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .securityAndPrivacyContainer {
        margin-bottom: 0;
    }
}