.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-left: 50px;
    margin-top: 1rem;
    overflow-x: hidden;
}

.heading {
    padding: 10px;
    color: black;
}

.whiteContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 30px;
    width: 100%;
    height: 100%;
}

.dateContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    margin: 1.2rem 0;
}

.dateField {
    background-color: var(--whitesmoke);
    color: black;
    border: 0;
    border-radius: 5px;
    padding: 15px 10px;
    height: 45px;
    text-align: center;
    text-align-last: center;
    outline: none;
    font-size: 0.8rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 110px;
}

.dateField::placeholder {
    text-align: center;
    text-align-last: center;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    background-color: var(--tabs-grey);
    justify-content: space-evenly;
    align-items: center;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.activeTab {
    border-top: 5px solid var(--purple);
    background-color: white;
    color: black;
}

label {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.searchBarContainer {
    display: flex;
    position: relative;
    /* justify-content: center; */
    align-items: center;
    background-color: var(--bg-white);
    border: 0;
    padding: 0.3rem;
    min-width: 250px;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
}

.searchIcon {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.3rem;
    position: absolute;
}

.input {
    display: flex;
    flex: 1;
    border: 0;
    color: grey;
    font-size: 0.75rem;
    background-color: var(--bg-white);
    text-align: center;
    padding: 0.8rem;
    /* height: 100%; */
    border-radius: 10px;
}

.input:focus {
    box-shadow: none;
}

.ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

#ChargesHistory {
    font-size: 0.8rem;
    text-align: center;
}

@media (max-width: 630px) {

    .mainContainer {
        padding: 10px;
        margin-left: 0;
    }

    .dateContainer {
        gap: 1rem;
        width: 100%;
        margin-bottom: 1rem;
        justify-content: flex-end;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {

    .mainContainer {
        padding: 1rem;
        margin-left: 0;
    }
}