.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 5px;
  width: 96%;
}

.required {
  color: red;
}

.box {
  display: flex;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.column {
  flex: 1;
  width: 50%;
  padding: 16px;
  border-right: 2px solid #ddd;
}

.column:last-child {
  border-right: none;
}

/* Title */
.title {
  font-size: 0.7rem;
  font-weight: bold;
  padding-bottom: 5px;
}

.searchBar {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
}

.listContainer {
  border: none;
}

.selectedContainer {
  border: 1px solid #ccc;
  overflow-y: auto;
  padding: 4px;
  width: 100%;
  height: 198px;
}

.selectedContainer :last-child {
  border-bottom: none;
}

.selectedContainer span {
  margin: 4px 0;
  font-size: 12px;
}

.search-container,
.diagnosisRightContainer {
  max-height: 370px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.diagnosisRightContainer {
  margin-top: 80px;
}

.diagnosisRightContainer h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.DiagnosisItemCode {
  display: flex;
}

.notesTextArea {
  width: 100%;
  height: 200px;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  font-size: 0.8rem;
  color: #333;
  outline: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notesTextArea::placeholder {
  color: #aaa;
}

.convertButton {
  width: 100%;
  padding: 6px 10px;
  background-color: #5e3ae4;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: .8rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.DiagnosisBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 1rem;
  flex: 1;
  width: 50%;
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--light-purple);
  color: white;
  padding: 0.5rem;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  height: 50px;
}

.accordionTitle:hover {
  background-color: var(--purple);
}

.DiagnosisItem {
  display: flex;
  padding: 1px 1px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.7rem;
  border-bottom: 1px solid rgb(199, 196, 196);
  border-top: none;
  gap: 0.7rem;
  width: 100%;
}


.DiagnosisItemDescription {
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
}

.checkmarkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 630px) {
  .container {
    width: 86%;
  }

  .search-container,
  .diagnosisRightContainer {
    width: 100%;
    padding: 15px;
  }

  .diagnosisRightContainer {
    margin-top: unset;
    max-height: 100%;
  }

  .title {
    font-size: 0.6rem;
  }

  .accordionTitle {
    font-size: 0.6rem;
    padding: 0.4rem;
    font-weight: 500;
  }

  .notesTextArea {
    padding: 8px;
    font-size: 0.6rem;
  }
}