.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    height: 100%;
}

.detailsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
}

.inputFieldContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 50%;
}

.newAdmissionContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding: 1rem;
}

.backButtonContainer {
    width: 100%;
    display: flex;
    padding-left: 10%;
}

.navigationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.eyeIcon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

.viewBtn {
    display: flex;
    width: 100%;
    background-color: var(--light-purple);
    color: white;
    max-width: 40px;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.viewBtn:hover {
    background-color: var(--purple);
    color: white;
}

.admissionDetailsContent {
    margin-top: 20px;
}

/* mobile */
@media (max-width: 630px) {
    .container {
        margin: 0;
        height: calc(100% - 160px);
    }

    .detailsContainer {
        border: none;
        border-radius: 0;
        box-shadow: none;
        transition: none;
        align-items: center;
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }

    .container {
        justify-content: center;
    }

    .inputFieldContainer {
        width: 100%;
    }

    .backButtonContainer {
        padding-left: 0;
        margin-top: 60px;
    }

    .newAdmissionContainer {
        padding: 1rem;
        margin-bottom: 0rem;
    }

}

@media (max-width: 352px) {
    .inputFieldContainer {
        flex: 1;
    }

    .selectInputField {
        min-width: 300px;
    }
}

@media (max-width: 1000px) and (orientation: landscape) {
    .container {
        margin-top: 0;
    }

    .newAdmissionContainer {
        margin-bottom: 0;
        padding: 0.5rem 1rem;
    }

    .backButtonContainer {
        padding-left: 2%;
        margin-top: 0.5rem;
    }

    .admissionDetailsContent {
        margin-top: 0;
    }
}