.dateInput {
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 130px;
}

.dateInput:focus {
    outline: none;
    border-color: var(--purple);
    box-shadow: 0 0 0 2px rgba(var(--purple-rgb), 0.2);
}

input:focus {
    box-shadow: none;
}

.required {
    color: red;
}

.toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    font-size: 0.8rem;
}

.mt0 {
    margin-top: 0;
}